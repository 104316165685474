.flash-card {
    perspective: 1000px;
    width: 500px;
    max-width: calc(100vw - 14px);
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .card-front,
    .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: transform 0.6s;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .card-front {
      transform: rotateY(0deg);
    }
  
    .card-back {
      transform: rotateY(-180deg);
    }
  
    &.flipped {
      .card-front {
        transform: rotateY(-180deg);
        background: transparent;
      }
  
      .card-back {
        transform: rotateY(0deg);
        background: transparent;
      }
    }
  }