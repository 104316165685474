.MuiCard-root {
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    .MuiButtonBase-root {
      &.MuiButton-containedPrimary {
        color: $white !important;
        background-color: $primary-color-card-bg !important;
        font-weight: 700 !important;
      }
    }
  }

  .dark {
    .MuiCard-root {
      .MuiButtonBase-root {
        &.MuiButton-containedPrimary {
          color: $white-dark !important;
          background-color: $red-dark!important;
        }
      }
    }
  }