@import "./Variables";
@import "./Default";
@import "./Mixins";
@import "./SpeakCard";
@import "./FlashCard";
@import "./EasterEgg";
@import "./MuiTable";
@import "./MuiCard";
@import "./MuiPaper";
@import "./MuiAccordion";
@import "./MuiForm.scss";

body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: #e3e3e3 !important;

  &.spring {
    background-image: url("../assets/background-spring.jpg");

    &.dark {
      background-image: url("../assets/background-spring-dark.jpg");
    }
  }

  &.summer {
    background-image: url("../assets/background-summer.jpg");

    &.dark {
      background-image: url("../assets/background-summer-dark.jpg");
    }
  }

  &.autumn {
    background-image: url("../assets/background-autumn.jpg");

    &.dark {
      background-image: url("../assets/background-autumn-dark.jpg");
    }
  }

  &.winter {
    background-image: url("../assets/background-winter.jpg");

    &.dark {
      background-image: url("../assets/background-winter-dark.jpg");
    }
  }

  &.default {
    background-image: url("../assets/background-default.jpg");

    &.dark {
      background-image: url("../assets/background-default-dark.jpg");
    }
  }

  section#content {
    margin: auto;
    margin-top: 85px;
    padding: 0.5rem;
    margin-bottom: 50px;
  }
}

.app-toolbar {
  width: calc(100% - 14px) !important;
  color: var(--black) !important;
  border-radius: 4px;
}

.mui-active {
  .MuiTableSortLabel-icon {
    color: var(--secondary-color);
  }
}

.text {
  display: inline;

  &.text-primary {
    color: $primary-color !important;
  }

  &.text-secondary {
    color: $secondary-color !important;
  }

  &.text-tertiary {
    color: $tertiary-color !important;
  }

  &.text-quaternary {
    color: $quaternary-color !important;
  }

  &.text-quinary {
    color: $quinary-color !important;
  }

  &.text-error {
    color: $error !important;
  }

  &.text-warning {
    color: $warning !important;
  }

  &.text-info {
    color: $info !important;
  }

  &.text-success {
    color: $success !important;
  }
}

section {
  &.max-width {
    width: 900px;
    max-width: calc(100vw - 31px) !important;
  }
}

.card-navigation {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0px;

  .MuiCardContent-root {
    padding: 0px !important;
    margin: 0px;
  }
}

.dark{
  .MuiDivider-root{
    background-color: $white-dark !important;
  }
}