.dark {
    .MuiFormControl-root {
        .MuiFormLabel-root {
            color: $white-dark;

            &.Mui-focused {
                color: $red-dark;
            }
        }

        .MuiInputBase-root {
            border-color: $white-dark;
            fieldset {
                border-color: $white-dark;
            }

            &:hover fieldset {
                border-color: darken($red-dark, 20%);
            }

            &.Mui-focused,
            &.Mui-focused fieldset {
                border-color: darken($red-dark, 20%);
            }


        }

    }
    .MuiInputBase-input {
        color: $white-dark;
    }

    .MuiOutlinedInput-root {
        border-color:$white-dark;
        // Styles for the root div
        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                color: $white-dark;
                border-color: $red-dark; // Replace with your desired color
            }
        }
    }
    .MuiSelect-select{
        border:1px solid $white-dark;
        border-radius:4px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        -ms-border-radius:4px;
        -o-border-radius:4px;
}
}
    