.MuiTable-root {
    &.alphabet-table {
        .MuiTableHead-root {
            background-color: var(--yellow);
        }

        .MuiTableRow-root {
            &:nth-child(4) {
                .MuiTableCell-root {
                    width: 165px;
                    white-space: nowrap;
                }
            }
        }
    }

    &.wordlist-table {
        .MuiTableRow-root {}
    }

    &.number-table {
        .MuiTableHead-root {
            .MuiTableCell-root:nth-child(1) {
                width: 10px;
            }
        }
    }
}

// Dark mode
.dark {
    .MuiTable-root {
        .MuiTableHead-root {
            background-color: darken($red-dark, 20%) !important;
            .MuiTableCell-root {
                color: $white-dark !important;
                .MuiButtonBase-root {
                    color: $white-dark !important;
                    &.Mui-active{
                        color: $yellow !important;
                    }
                }
            }
        }

        .MuiTableRow-root {
            .MuiTableCell-root {
                color: $white-dark !important;
            }
        }
    }
    .MuiToolbar-root {
        color:$white-dark !important;
    }
}