.speakCardContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    >* {
        flex-grow: 1;
        flex-basis: calc((900px - 100%) * 999);
        margin: 5px;
    }

    >.speak-card>button {
        height: 100%;
    }
}

.speak-card.MuiCard-root {

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }

    .MuiButtonBase-root {
        width: 100%;
        color: $white !important;
        background-color: $primary-color-card-bg !important;
        font-weight: 700 !important;
    }
}

.dark{
    .speak-card.MuiCard-root {
        .MuiButtonBase-root {
            color: $white-dark !important;
            background-color: darken($red-card-bg,20%) !important;
        }
    }
}