.easteregg {
    margin-right: 8px;
    z-index: 99999;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    transform: translateY(0) scale(1);
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  
    &.clicked {
      animation: scrollAndScale 2s forwards;
  
      @keyframes scrollAndScale {
        0% {
          transform: translateY(0) scale(0);
        }
        50% {
          transform: translateY(-50px) scale(1.5);
          -webkit-transform: translateY(-50px) scale(1.5);
          -moz-transform: translateY(-50px) scale(1.5);
          -ms-transform: translateY(-50px) scale(1.5);
          -o-transform: translateY(-50px) scale(1.5);
        }
        100% {
          transform: translateY(0) scale(1);
        }
      }
    }
  }