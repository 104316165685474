//overriding styles for MuiAccordion in scss
.MuiAccordion-root {
    .MuiAccordionSummary-content{
        &.Mui-expanded{
            margin:inherit;
        }
    }
    .MuiCollapse-root{
        &.MuiCollapse-entered{
            margin:inherit;
        }
    }
}
