.MuiPaper-root {
    background-color: $white-card-bg !important;
    backdrop-filter: blur(10px) !important;

    &.max-width {
        width: 900px;
        max-width: calc(100vw - 31px) !important;
    }

    &.alert {
        margin: 14px 0px;

        p {
            padding: 0px;
            margin: 0px;
        }

        &.alert-error {
            @include alert-style($error);
        }

        &.alert-warning {
            @include alert-style($warning);
        }

        &.alert-info {
            @include alert-style($info);
        }

        &.alert-success {
            @include alert-style($success);
        }

        &.alert-default {
            @include alert-style($black);
        }
    }

    &.table-filter {
        .MuiCardContent-root {
            padding: 16px;
        }
    }

    .MuiBottomNavigation-root {
        background-color: transparent;
    }

    &.MuiCard-root {
        .MuiCardContent-root {
            padding: 16px;
            h4 {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}

.dark {
    .MuiPaper-root {
        background-color: $quinary-color-card-bg !important;
        color: $white-dark !important;

        .MuiSvgIcon-root {
            color: $white-dark !important;
        }

        &.alert{
            &.alert-error {
                @include alert-style($error-dark);
            }
            &.alert-warning {
                @include alert-style($warning-dark);
            }
            &.alert-info {
                @include alert-style($info-dark);
            }
            &.alert-success {
                @include alert-style($success-dark);
            }
            &.alert-default {
                @include alert-style($black-dark);
            }
        }
    }

    .MuiBottomNavigation-root {
        a {
            color: $white-dark;

            &:hover,
            &:focus,
            &:active,
            &.Mui-selected {
                color: $red-dark !important;
                .MuiSvgIcon-root {
                    color: $red-dark !important;
                }
            }
        }
    }
}